import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as Sentry from "@sentry/react";

import { AuthProvider } from "./context/AuthContext";
import { QuickBooksProvider } from "./context/QuickBooksContext";

import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
if (
  REACT_APP_SENTRY_DSN != null &&
  REACT_APP_SENTRY_DSN != undefined &&
  REACT_APP_SENTRY_DSN != ""
)
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      //  https://docs.sentry.io/platforms/javascript/configuration/integrations/captureconsole/
      Sentry.captureConsoleIntegration(["error"]), // ['log', 'info', 'warn', 'error', 'debug', 'assert']
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <QuickBooksProvider>
      <App />
    </QuickBooksProvider>
  </AuthProvider>
);
