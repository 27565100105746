import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Fab,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Accessory from "../../../../shop/Orders/Order/Accessory";
import BenchWork from "../../../../shop/Orders/Order/BenchWork";
import Cleat from "../../../../shop/Orders/Order/Cleat";
import CopingCap from "../../../../shop/Orders/Order/CopingCap";
import Panel from "../../../../shop/Orders/Order/Panel";
import SplicePlate from "../../../../shop/Orders/Order/SplicePlate";
import TrimAndFlashing from "../../../../shop/Orders/Order/TrimAndFlashing";
import estimatePdfDownloadHandler from "../../../../../helper/estimatePdfDownloader";
import { Check, Download, ExpandMore } from "@mui/icons-material";
import ReactSignatureCanvas from "react-signature-canvas";
import getEstimateDocDefinition from "../../../../../pdf-helpers/EstimateHelper";
import { AuthContext } from "../../../../../context/AuthContext";
import TermsOfAgreement from "../../../../guest/TermsOfAgreement";
import { SnackAlertContext } from "../../../../../context/SnackAlertContext";
import { LoadingButton } from "@mui/lab";
import { orderTotals } from "../../../../../components/NewDetail/calculations/orderTotals";
import moment from "moment/moment";
import DeclineEstimateModal from "./DeclineEstimateModal";
import OrderTitle from "../../../../../components/OrderTitle";
import { useTheme } from "@emotion/react";
import CommentSection from "./CommentSection";
import ShopS3PresignedCardImage from "../../../../../components/ShopS3PresignedCardImage";
import RenewEstimateModal from "./RenewEstimateModal";
import {
  applyMarkup,
  toThreeDecimals,
  toTwoDecimals,
} from "../../../../../components/NewDetail/calculations/utils";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import useShopNotes from "../../../../../hooks/useShopNotes";
import FabricatorNotes from "../../../../shop/Orders/FabricatorNotes";
import SetPrimaryContactDialog from "./SetPrimaryContactDialog";
import FlatSheet from "../../../../shop/Orders/Order/FlatSheet";
import Coil from "../../../../shop/Orders/Order/Coil";
import ApproveEstimateDialog from "../../../../../components/ApproveEstimateDialog";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-Regular.ttf",
    bold: "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-Medium.ttf",
    italics:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-BoldItalic.ttf",
    bolditalics:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-MediumItalic.ttf",
  },
  AlexBrush: {
    normal: `https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/AlexBrush-Regular.ttf`,
  },
};

// Basically a Copy of <ApprovalEstimate/> but doesnt use the link token and instead uses authToken
const OrderWithEstimateDetails = ({
  order,
  getOrderById,
  canApproveEstimate,
  onDeclineEstimate,
  isEstimateExpired,
  onRenewEstimate,
  isLoading,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();
  const controller = new AbortController();
  const { orderId } = useParams();
  const [productMap, setProductMap] = useState(new Map());
  const [refreshing, setRefreshing] = useState(false);
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const [isSalesTaxApplicable, setIsSalesTaxApplicable] = useState(false);
  const signatureRef = useRef();
  const [isApproving, setIsApproving] = useState(false);
  const { openSnackMessage } = useContext(SnackAlertContext);
  const [criticalDates, setCriticalDates] = useState(null);

  const [comments, setComments] = useState([]);
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [vendorMarkupPercentage, setVendorMarkupPercentage] = useState(
    order.vendorMarkupPercentage
  );
  const { shopNotes, addShopNote, isLoadingNotes } = useShopNotes(
    authToken,
    order.id,
    false
  );

  const [
    isEstimateTermsOfServiceAgreementChecked,
    setIsEstimateTermsOfServiceAgreementChecked,
  ] = useState(false);

  // User Confirm Gauge
  const [isEstimateGaugeConfirmed, setIsEstimateGaugeConfirmed] =
    useState(false);

  // User Confirm Material/Color
  const [
    isEstimateMaterialAndColorConfirmed,
    setIsEstimateMaterialAndColorConfirmed,
  ] = useState(false);

  const [approveDialogOpen, setApproveDialogOpen] = useState(false);

  const addComment = async (commentText) => {
    try {
      setIsAddingComment(true);

      let requestBody = {
        comment: commentText,
      };

      const response = await ezorder.post(
        `/orders/${order.id}/comment`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Comment Added");
      getOrderComments();
    } catch (error) {
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsAddingComment(false);
    }
  };

  const [declineEstimateModalOpen, setDeclineEstimateModalOpen] =
    useState(false);
  const [renewEstimateModalOpen, setRenewEstimateModalOpen] = useState(false);
  const [primaryContactDialogOpen, setPrimaryContactDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const parseOrder = async () => {
    try {
      let orderDetails = order;

      const prodMap = new Map();
      for (let i = 0; i < orderDetails.items.length; i++) {
        let orderItem = orderDetails.items[i];
        prodMap.set(`${orderItem.objectType}`, orderItem);
      }
      setProductMap(prodMap);
      console.log("PRODUCT MAP", prodMap);
    } catch (error) {
      console.log("ERRORRRRR");
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const handleApprove = async (base64Signature, orderContact) => {
    console.log("Handle Approve", base64Signature, orderContact);
    generateSignedEstimateForOrder(
      base64Signature,
      formik.values.signee,
      formik.values.signeeEmail,
      isSalesTaxApplicable,
      orderContact
    );
  };

  let getEstimateBlobPromise = function (pdfGenerator) {
    return new Promise((resolve, reject) => {
      pdfGenerator.getBlob((blob) => {
        console.log("BLOB", blob);
        resolve(blob);
      });
    });
  };

  const generateSignedEstimateForOrder = async (
    signatureBase64,
    signeeFullName,
    signeeEmail,
    isSalesTaxApplicable,
    orderContact
  ) => {
    try {
      setIsApproving(true);
      if (Object.keys(formik.errors).length)
        throw "Please enter your full name and email";
      if (!isEstimateTermsOfServiceAgreementChecked)
        throw "You must agree to Industry's Service Agreement terms before approving this quote";
      if (order.estimateVersion == "v2" && !isEstimateMaterialAndColorConfirmed)
        throw "You must confirm the selected material and color are correct";
      if (order.estimateVersion == "v2" && !isEstimateGaugeConfirmed)
        throw "You must confirm that the gauge/thickness is correct";
      // Then Generate Estimate
      const docDef = await getEstimateDocDefinition(
        orderId,
        authToken,
        false,
        null,
        signatureBase64,
        signeeFullName,
        true,
        isSalesTaxApplicable,
        null,
        null,
        signeeEmail
      );

      // let fonts = {
      //   Roboto: {
      //     normal: "fonts/Roboto-Regular.ttf",
      //     bold: "fonts/Roboto-Medium.ttf",
      //     italics: "fonts/Roboto-Italic.ttf",
      //     bolditalics: "fonts/Roboto-MediumItalic.ttf",
      //   },
      //   AlexBrush: {
      //     normal: "fonts/AlexBrush-Regular.ttf",
      //   },
      // };
      // let pdfGenerator = pdfMake.createPdf(docDef, null, fonts); //.open();
      let pdfGenerator = pdfMake.createPdf(docDef); //.open();

      let estimatePdfBlob = await getEstimateBlobPromise(pdfGenerator);
      const presignedUrlResponse = await createWritePresignedUrl(
        "WRITE",
        "estimate.pdf",
        estimatePdfBlob.contentType,
        "signed-estimates"
      );
      const presignedUploadUrl = presignedUrlResponse.uploadUrl;
      const s3Key = presignedUrlResponse.key;

      const fetchResult = await fetch(presignedUploadUrl, {
        method: "PUT",
        body: estimatePdfBlob,
        headers: {
          "Content-Type": estimatePdfBlob.contentType, // DO NOT PASS IN A BEARER TOKEN
        },
      });

      // Update Order with new Estimate
      // Create endpoint to update status and set signed estimate s3 key
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await ezorder.put(
        `/orders/${order.id}/sign-estimate`,
        {
          signedEstimatePDF: s3Key,
          estimateApprovalDate: new Date(),
          isEstimateTermsOfServiceAgreementChecked,
          isEstimateMaterialAndColorConfirmed,
          isEstimateGaugeConfirmed,
          orderContact,
          estimateApprovedByFullName: signeeFullName,
          estimateApprovedByEmail: signeeEmail,
        },
        data
      );
      if (response.status == 200 || response.status === 201) {
        openSnackMessage(
          "success",
          "Quote Approved",
          "Thank you! Once your order is processed, you will receive the invoice and order progress notifications via email"
        );
        navigate("/orders");
      } else {
        openSnackMessage("error", "Approval Failed");
        Sentry.captureException(new Error("Failed to approve estimate"));
      }
    } catch (error) {
      Sentry.captureException(error, "bernard test");
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage(
          "error",
          error.response.data.error,
          "Something went wrong (e5)"
        );
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error, "Something went wrong (e6)");
      }
    } finally {
      setIsApproving(false);
    }
  };

  const generateMarkedUpEstimateForOrder = async () => {
    try {
      setIsApproving(true);
      // Then Generate Estimate
      const docDef = await getEstimateDocDefinition(
        orderId,
        authToken,
        false, //isNoAuth
        null, // accessToken
        null, // signatureBase64
        null, // signeeFullName
        true, // isCustomer
        null,
        vendorMarkupPercentage,
        true, // includeCompanyLogo
        null // signeeEmail
      );

      // pdfMake.createPdf(docDef).open();

      let pdfGenerator = pdfMake.createPdf(docDef); //.open();
      // pdfGenerator.open();
      let pdfName = `VENDOR-QUOTE-${order.id}-${order.company.name}-${order.project.name}`;
      pdfGenerator.download(pdfName);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage(
          "error",
          error.response.data.error,
          "Something went wrong (e7)"
        );
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error, "Something went wrong (e8)");
      }
    } finally {
      setIsApproving(false);
    }
  };

  const saveVendorMarkup = async () => {
    try {
      setIsApproving(true);

      // Update Order with new Vendor Markup
      // Create endpoint to update status and set signed estimate s3 key
      const response = await ezorder.put(
        `/orders/${order.id}/vendor-markup`,
        {
          vendorMarkupPercentage,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      openSnackMessage("success", "Markup Saved");
      getOrderById();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsApproving(false);
    }
  };

  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource
  ) => {
    console.log("Create Presigned URL");

    const identifier =
      order.company && order.company.id ? order.company.id : "GUEST";

    const response = await ezorder.post(
      "/files/presignedUrl",
      { actionType, fileName, contentType, resource, identifier },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };

  const getOrderComments = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      const res = await ezorder.get(`/orders/${orderId}/comments`, data);
      setComments(res.data.comments);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getOrderCriticalDatesById = async (orderId) => {
    try {
      setLoading(true);

      const response = await ezorder.get(`/orders/${orderId}/critical-dates`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCriticalDates(response.data.criticalDates);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const signeeFormSchema = Yup.object().shape({
    signee: Yup.string().required("Full Name is required"),
    signeeEmail: Yup.string().email().required("Email is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      signee: decoded.fullName ? decoded.fullName : "",
      signeeEmail: decoded.email ? decoded.email : "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: signeeFormSchema,
    onSubmit: (values) => {
      // handleSubmit(values);
    },
  });

  useEffect(() => {
    if (order) {
      getOrderCriticalDatesById(order.id);
    }
  }, [order]);

  useEffect(() => {
    const controller = new AbortController();
    if (orderId) {
      getOrderComments(controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  useEffect(() => {
    if (order) parseOrder(order);
  }, [order]);

  if (!order) return null;

  let totals = null;
  if (order.estimatePdf) {
    totals = orderTotals(order.items, order.skidCharge);
  }
  return (
    <Box sx={{ padding: "16px", marginBottom: "100px" }}>
      <OrderTitle order={order} />
      {criticalDates && (
        <Box>
          <Typography variant="h6">Order History</Typography>
          {criticalDates.estimateRequestedOn && (
            <Typography>
              Quote Requested:{" "}
              {criticalDates.estimateRequestedBy
                ? `${criticalDates.estimateRequestedBy.fullName}`
                : ""}{" "}
              - {moment(criticalDates.estimateRequestedOn).format("MM/DD/YY")}{" "}
            </Typography>
          )}

          {criticalDates.estimateLastSentForApproval && (
            <Typography>
              Order Quoted:{" "}
              {criticalDates.estimateSentBy
                ? `${criticalDates.estimateSentBy.fullName} - `
                : ""}
              {criticalDates.estimateSentBy &&
              criticalDates.estimateLastSentForApproval
                ? " - "
                : ""}
              {moment(criticalDates.estimateLastSentForApproval).format(
                "MM/DD/YY"
              )}{" "}
            </Typography>
          )}

          {criticalDates.estimateApprovalDate && (
            <Typography>
              Quote Approved:{" "}
              {criticalDates.estimateApprovedBy
                ? `${criticalDates.estimateApprovedBy.fullName}`
                : ""}
              {criticalDates.estimateApprovedBy &&
              criticalDates.estimateApprovalDate
                ? " - "
                : ""}
              {moment(criticalDates.estimateApprovalDate).format("MM/DD/YY")}
            </Typography>
          )}

          {criticalDates.shopEstimateCompletionDate && (
            <Typography>
              When Needed By Customer:{" "}
              {criticalDates.estimateRequestedBy
                ? `${criticalDates.estimateRequestedBy.fullName}`
                : ""}
              {criticalDates.estimateRequestedBy &&
              criticalDates.customerRequestedCompletionDate
                ? " - "
                : ""}
              {moment(criticalDates.customerRequestedCompletionDate).format(
                "MM/DD/YY"
              )}
            </Typography>
          )}
          <Typography>
            Estimated Completion Date:{" "}
            {criticalDates.shopEstimateCompletionDate
              ? moment(criticalDates.shopEstimateCompletionDate).format(
                  "MM/DD/YY"
                )
              : "TBD"}
          </Typography>
          {criticalDates.shopCompletionDate && (
            <Typography>
              Completed:{" "}
              {criticalDates.assignedShopOrderLead
                ? `${criticalDates.assignedShopOrderLead.fullName}`
                : ""}
              {criticalDates.assignedShopOrderLead &&
              criticalDates.shopCompletionDate
                ? " - "
                : ""}
              {moment(criticalDates.shopCompletionDate).format("MM/DD/YY")}
            </Typography>
          )}

          {criticalDates.deliveredOn && (
            <Typography>
              Delivered:{" "}
              {criticalDates.deliveredBy ? `${criticalDates.deliveredBy}` : ""}
              {criticalDates.deliveredBy && criticalDates.deliveredOn
                ? " - "
                : ""}
              {moment(criticalDates.deliveredOn).format("MM/DD/YY")}
            </Typography>
          )}
          {criticalDates.pickedUpOn && (
            <Typography>
              Picked Up:{" "}
              {criticalDates.pickedUpBy ? `${criticalDates.pickedUpBy}` : ""}
              {criticalDates.pickedUpBy && criticalDates.pickedUpOn
                ? " - "
                : ""}
              {moment(criticalDates.pickedUpOn).format("MM/DD/YY")}
            </Typography>
          )}
          {criticalDates.fulfilledOn && (
            <Typography>
              Fulfilled:{" "}
              {criticalDates.fulfilledBy
                ? `${criticalDates.fulfilledBy.fullName}`
                : ""}
              {criticalDates.fulfilledBy && criticalDates.fulfilledOn
                ? " - "
                : ""}
              {moment(criticalDates.fulfilledOn).format("MM/DD/YY")}
            </Typography>
          )}
        </Box>
      )}
      <Box>
        {order.estimatePdf && (
          <Button
            onClick={() =>
              estimatePdfDownloadHandler(
                order.estimatePdf,
                authToken,
                false,
                null,
                `CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
              )
            }
          >
            <Download color="primary" />
            Quote PDF
          </Button>
        )}
        {order.isEstimateApproved && (
          <Button
            onClick={() => {
              console.log("CLICKED");
              estimatePdfDownloadHandler(
                order.signedEstimatePDF,
                authToken,
                false,
                null,
                `SIGNED-CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
              );
            }}
          >
            <Download color="primary" />
            Signed Quote PDF
          </Button>
        )}
      </Box>

      {order.isEstimateApproved && order.orderStatus.sortValue >= 70 && (
        <Box sx={{ marginTop: "32px" }}>
          <Card sx={{ marginTop: "8px", padding: "16px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <Typography variant="h7" sx={{}}>
                Pick Up
              </Typography>
              <TextField
                size="small"
                fullWidth
                label="Pick Up Status"
                value={order.pickUpStatus}
              />
              {order.pickUpStatus == "Picked Up - 100%" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Picked Up By"
                    name="Picked Up By"
                    size="small"
                    fullWidth
                    value={order.pickedUpBy}
                    disabled
                  />
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Picked Up On"
                        value={order.pickedUpOn}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            sx={{}}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              )}
              {order.pickupStatus == "Delivered - 100%" && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "8px",
                  }}
                >
                  <TextField
                    variant="outlined"
                    label="Delivered By"
                    name="Delivered By"
                    size="small"
                    fullWidth
                    value={order.deliveredBy}
                    disabled
                  />
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Delivered On"
                        value={order.deliveredOn}
                        disabled
                        renderInput={(params) => (
                          <TextField
                            size="small"
                            fullWidth
                            sx={{}}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              )}
              <Grid
                container
                padding={0}
                spacing={0}
                alignItems="center"
                justifyContent="center"
              >
                {
                  //Saved Images
                  order.pickupImages.map((s3Key, index) => (
                    <Grid
                      item
                      key={index}
                      style={{
                        position: "relative",
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <ShopS3PresignedCardImage
                        key={index}
                        width={100}
                        s3Key={s3Key}
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Grid>
                  ))
                }
              </Grid>
              {/* <Typography sx={{ margin: 0 }}>Pick Up Notes:</Typography> */}
              {order &&
                order.pickUpNotes &&
                order.pickUpNotes.map((notes, index) => {
                  let createdAt = moment(new Date(notes.createdAt)).local();
                  let weekOld = moment(new Date(createdAt)).local();
                  weekOld.add(7, "d");
                  let isWeekOld = moment() > weekOld;

                  return (
                    <Card
                      key={index}
                      style={{ padding: 16, marginTop: index > 0 ? 8 : 0 }}
                    >
                      <Box style={{ display: "flex", flexDirection: "row" }}>
                        {notes.user ? (
                          <Typography
                            style={{ marginRight: 8, fontWeight: "bold" }}
                          >
                            {notes.user.fullName}
                          </Typography>
                        ) : (
                          <Typography
                            style={{ marginRight: 8, fontWeight: "bold" }}
                          >
                            {notes.guestName}
                          </Typography>
                        )}
                        <Typography>
                          {isWeekOld
                            ? createdAt.format("MM/DD/YYYY")
                            : createdAt.fromNow()}
                        </Typography>
                      </Box>
                      <Typography style={{ marginTop: 8 }}>
                        {notes.text}
                      </Typography>
                    </Card>
                  );
                })}
            </Box>
          </Card>
        </Box>
      )}

      {order.isEstimateApproved && order.orderStatus.sortValue >= 60 && (
        <Card sx={{ marginTop: "8px", padding: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <Typography variant="h7" sx={{}}>
              Progress{}
            </Typography>
            {order.orderStatus.name == "IN_PROGRESS" && (
              <Typography>
                Estimated Completion Date:{" "}
                {order.shopEstimateCompletionDate
                  ? moment(order.shopEstimateCompletionDate).format("MM/DD/YY")
                  : "TBD"}
              </Typography>
            )}
            {order.orderStatus.name == "READY_FOR_PICKUP" &&
              order.shopCompletionDate && (
                <Typography>
                  Completion Date:{" "}
                  {moment(order.shopCompletionDate).format("MM/DD/YY")}
                </Typography>
              )}

            <TextField
              size="small"
              fullWidth
              label="Order Progress Status"
              value={
                order.shopStatus == "Paused" ? "In-Progress" : order.shopStatus
              }
            />

            <Box>
              {(order.shopStatus == "In-Progress" ||
                order.shopStatus == "Partially Complete") && (
                <Box
                  sx={{
                    marginTop: "8px",
                    paddingTop: "8px",
                  }}
                >
                  <FormGroup sx={{}}>
                    <FormLabel
                      sx={{
                        fontWeight: "bold",
                        overflow: "hidden",
                        color: "#fff",
                      }}
                    >
                      Shop Progress:
                    </FormLabel>
                    {/* <FormControlLabel
                      // disabled={loading}
                      control={
                        <Checkbox
                          disabled={true}
                          checked={order.shopProgressAreFlatsSheared}
                        />
                      }
                      label={"Flats Sheared"}
                    /> */}
                    <FormControlLabel
                      // disabled={loading}
                      control={
                        <Checkbox
                          disabled={true}
                          checked={order.shopProgressIsInFabrication}
                        />
                      }
                      label={"In Fabrication"}
                    />
                    <FormControlLabel
                      // disabled={loading}
                      control={
                        <Checkbox
                          disabled={true}
                          checked={order.shopProgressArePanelsBeingRan}
                        />
                      }
                      label={"Panels Being Run"}
                    />
                  </FormGroup>
                </Box>
              )}
            </Box>
            <Grid
              container
              padding={0}
              spacing={0}
              alignItems="center"
              justifyContent="center"
            >
              {
                //Saved Images
                order.shopImages.map((s3Key, index) => (
                  <Grid
                    item
                    key={index}
                    style={{
                      position: "relative",
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    <ShopS3PresignedCardImage
                      key={index}
                      width={100}
                      s3Key={s3Key}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Grid>
                ))
              }
            </Grid>
            <FabricatorNotes shopNotes={shopNotes} />
          </Box>
        </Card>
      )}

      <Box sx={{ marginTop: "32px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {order.company.isVendorOrSupplier
                        ? "Customer"
                        : "Project"}
                    </TableCell>
                    {order.company.isVendorOrSupplier && (
                      <TableCell>Project</TableCell>
                    )}
                    <TableCell>Sub-Project</TableCell>
                    <TableCell>PO #</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {order.project.name}
                    </TableCell>
                    {order.company.isVendorOrSupplier && (
                      <TableCell component="th" scope="row">
                        {order.vendorProjectName}
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {order.subProject}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.poNumber}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {order.isCustomMaterial ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Custom Material</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{order.customColor}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Color/Material</TableCell>
                      <TableCell>Gauge</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {order.color} - {order.manufacturer}
                      </TableCell>
                      <TableCell>{order.gauge}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
      </Box>

      <Box sx={{ marginTop: "32px" }}>
        <Typography variant="h5">Components Included</Typography>

        <Box sx={{ padding: "8px" }}>
          {order?.items?.find((i) => i.objectType === "FlatSheet") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Flat Sheet</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "Coil") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Coil</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "Panel") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Panel</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "TrimAndFlashing") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Tim & Flashing</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "BenchWork") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>BenchWork</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "CopingCap") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Coping Cap</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "CopingCapCleat") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Coping Cap Cleat</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "SplicePlate") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Splice Plate</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "Accessory") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Extra Accessories</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Typography variant="h5" sx={{ marginTop: "32px" }}>
        Order Summary
      </Typography>

      {productMap.get("FlatSheet") && (
        <FlatSheet
          flatSheet={productMap.get("FlatSheet")}
          sx={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      {productMap.get("Coil") && (
        <Coil
          coil={productMap.get("Coil")}
          sx={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      {productMap.get("Panel") && (
        <Panel
          panel={productMap.get("Panel")}
          sx={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}
      {productMap.get("TrimAndFlashing") && (
        <TrimAndFlashing
          trim={productMap.get("TrimAndFlashing")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("BenchWork") && (
        <BenchWork
          benchWork={productMap.get("BenchWork")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("CopingCap") && (
        <CopingCap
          copingCap={productMap.get("CopingCap")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("CopingCapCleat") && (
        <Cleat
          cleat={productMap.get("CopingCapCleat")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("SplicePlate") && (
        <SplicePlate
          splicePlate={productMap.get("SplicePlate")}
          style={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}
      {productMap.get("Accessory") && (
        <Accessory
          accessory={productMap.get("Accessory")}
          style={{ marginTop: "32px", marginBottom: "32px" }}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      <Typography variant="h5" sx={{ marginTop: "32px" }}>
        Skid Charge (Pallet Charge) - $
        {Math.round(parseFloat(order.skidCharge ? order.skidCharge : 0.0))}
      </Typography>

      {order.estimatePdf && (
        <Typography variant="h5" sx={{ textAlign: "left", marginTop: "32px" }}>
          Total: ${Math.round(parseFloat(totals.outTheDoorCost))}
        </Typography>
      )}
      {order.company.isVendorOrSupplier && (
        <Box
          sx={{
            marginTop: "32px",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h5" color="primary">
            Vendor Markup
          </Typography>
          <Card
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                type="number"
                color="primary"
                margin="normal"
                label="Markup %"
                size="small"
                value={vendorMarkupPercentage}
                onFocus={(e) => e.target.select()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value)
                    setVendorMarkupPercentage(toTwoDecimals(e.target.value));
                  else {
                    setVendorMarkupPercentage(toTwoDecimals(0));
                  }
                }}
              />
              <LoadingButton
                disabled={
                  vendorMarkupPercentage == order.vendorMarkupPercentage
                }
                sx={{ marginLeft: "8px" }}
                loading={isApproving}
                color="success"
                variant="contained"
                onClick={() => {
                  saveVendorMarkup();
                }}
              >
                Save Markup
              </LoadingButton>
            </Box>

            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Original</TableCell>
                    <TableCell align="right">Mark Up</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      $
                      {Math.round(
                        toThreeDecimals(
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost
                        )
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ color: "lightgreen" }}>
                      + $
                      {Math.round(
                        applyMarkup(
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost,
                          vendorMarkupPercentage
                        ) -
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost
                      )}
                    </TableCell>
                    <TableCell align="right">
                      $
                      {Math.round(
                        applyMarkup(
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost,
                          vendorMarkupPercentage
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography>
              * To include your company logo on the PDF, upload your logo on
              your company settings
            </Typography>
            {/* {order.orderStatus.name != "SAVED" &&
              order.orderStatus.name != "QUOTE_REQUESTED" && ( */}
            <LoadingButton
              loading={isApproving}
              onClick={() => {
                generateMarkedUpEstimateForOrder();
              }}
            >
              <Download color="primary" />
              Vendor Markup Quote
            </LoadingButton>

            {/* )} */}
          </Card>
        </Box>
      )}

      <SetPrimaryContactDialog
        defaultName={decoded.fullName}
        defaultPhone={decoded.phone}
        defaultEmail={decoded.email}
        open={primaryContactDialogOpen}
        onSubmit={(orderContact) => {
          console.log("SetPrimaryContactDialog.onSubmit", orderContact);
          if (order.estimateVersion == "v1") {
            console.log("EstimateVersion == v1");
            let base64Image = signatureRef.current
              .getTrimmedCanvas()
              .toDataURL("image/png");
            console.log("Signature base64Image", base64Image);
            handleApprove(base64Image, orderContact);
          } else {
            console.log("EstimateVersion != v1");
            handleApprove(null, orderContact);
          }
        }}
        handleClose={() => setPrimaryContactDialogOpen(false)}
        isLoading={loading || isLoading || isApproving}
      />
      <DeclineEstimateModal
        open={declineEstimateModalOpen}
        onDeclineEstimate={onDeclineEstimate}
        handleClose={() => setDeclineEstimateModalOpen(false)}
        isLoading={loading || isLoading}
      />
      <RenewEstimateModal
        open={renewEstimateModalOpen}
        onRenewEstimate={onRenewEstimate}
        handleClose={() => setRenewEstimateModalOpen(false)}
        loading={loading || isLoading}
      />
      <CommentSection
        comments={comments}
        addComment={addComment}
        loading={isAddingComment}
      />

      {order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" &&
        canApproveEstimate && (
          <>
            <Fab
              sx={{
                position: "fixed",
                bottom: 70,
                right: isSmallScreen ? 16 : 56,
              }}
              color="success"
              variant="extended"
              onClick={() => {
                console.log("CLICKED");
                setApproveDialogOpen(true);
              }}
            >
              Review and Approve
            </Fab>
            <ApproveEstimateDialog
              open={approveDialogOpen}
              setOpen={setApproveDialogOpen}
              // titleContent={(isExpanded) => {
              //   return (
              //     <Box
              //       style={{
              //         width: "100%",
              //         display: "flex",
              //         flexDirection: "row",
              //         alignItems: "center",
              //       }}
              //     >
              //       <Typography
              //         variant="h6"
              //         color="primary"
              //         style={{ flex: 1, fontWeight: "bold" }}
              //       >
              //         Review And Approve
              //       </Typography>
              //       {!isExpanded && (
              //         <Button
              //           sx={{ marginRight: "8px" }}
              //           loading={isApproving}
              //           color="success"
              //           variant="contained"
              //           onClick={null}
              //         >
              //           Review
              //         </Button>
              //       )}
              //     </Box>
              //   );
              // }}
              actionAreaContent={() => {
                return (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {order.estimateVersion == "v1" && (
                        <Button
                          onClick={() => {
                            signatureRef.current.clear();
                          }}
                        >
                          Clear
                        </Button>
                      )}

                      {/* <Button
                  sx={{ marginLeft: "8px" }}
                  variant="contained"
                  onClick={() => {
                    console.log(order);
                    setDeclineEstimateModalOpen(true);
                  }}
                >
                  Request Changes
                </Button> */}
                      <LoadingButton
                        sx={{ marginLeft: "8px" }}
                        variant="contained"
                        color="success"
                        loading={isApproving}
                        onClick={() => {
                          try {
                            if (Object.keys(formik.errors).length)
                              throw "Please enter your full name and email";

                            if (!isEstimateTermsOfServiceAgreementChecked)
                              throw "You must agree to Industry's Service Agreement terms before approving this quote";
                            if (
                              order.estimateVersion == "v2" &&
                              !isEstimateMaterialAndColorConfirmed
                            )
                              throw "You must confirm the selected material and color are correct";
                            if (
                              order.estimateVersion == "v2" &&
                              !isEstimateGaugeConfirmed
                            )
                              throw "You must confirm that the gauge/thickness is correct";

                            if (
                              order.estimateVersion == "v1" &&
                              signatureRef.current.isEmpty()
                            ) {
                              throw "Signature required";
                            }

                            setPrimaryContactDialogOpen(true);
                          } catch (error) {
                            openSnackMessage("error", error);
                          }
                        }}
                      >
                        Approve
                      </LoadingButton>
                    </Box>
                  </Box>
                );
              }}
            >
              <Box>
                <Box
                  sx={{
                    marginTop: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    padding: "16px",
                  }}
                >
                  {order.isUnconfirmedCutlist ? (
                    <Box>
                      <Typography sx={{ color: "red" }}>
                        THIS QUOTE IS FOR ESTIMATING ONLY AND CANNOT BE APPROVED
                        FOR PRODUCTION. TO ORDER THESE ITEMS, YOU MUST REQUEST A
                        NEW QUOTE FOR PRODUCTION.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          sx={{ marginLeft: "8px" }}
                          variant="contained"
                          onClick={() => {
                            navigate(
                              `/quotes/${order.id}/resubmit-quote-request`
                            );
                          }}
                        >
                          Edit and Request a New Quote
                        </Button>
                        <Button
                          sx={{ marginLeft: "8px" }}
                          variant="contained"
                          onClick={() => {
                            console.log(order);
                            setRenewEstimateModalOpen(true);
                          }}
                        >
                          Request A New Quote
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <TermsOfAgreement
                        estimateVersion={order.estimateVersion}
                      />
                      {isEstimateExpired ? (
                        <Box sx={{ width: "100%", marginTop: "16px" }}>
                          <Typography sx={{ color: "red" }}>
                            This Quote is no longer valid for approval. Our
                            quotes are valid for 30 days.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              sx={{ marginLeft: "8px" }}
                              variant="contained"
                              onClick={() => {
                                navigate(
                                  `/quotes/${order.id}/resubmit-quote-request`
                                );
                              }}
                            >
                              Edit and Request a New Quote
                            </Button>
                            <Button
                              sx={{ marginLeft: "8px" }}
                              variant="contained"
                              onClick={() => {
                                console.log(order);
                                setRenewEstimateModalOpen(true);
                              }}
                            >
                              Request A New Quote
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ width: "100%", marginTop: "16px" }}>
                          <Box sx={{ marginBottom: "8px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                magin: 0,
                              }}
                            >
                              <Checkbox
                                style={{
                                  magin: 0,
                                  padding: 0,
                                }}
                                checked={isSalesTaxApplicable}
                                onChange={(e) => {
                                  setIsSalesTaxApplicable(e.target.checked);
                                }}
                              />
                              <Typography
                                color="primary"
                                sx={{ fontWeight: "bold" }}
                              >
                                Is Sales Tax Applicable to this Order?
                              </Typography>
                            </div>

                            <Typography
                              sx={{ marginLeft: "16px", fontSize: "14px" }}
                            >
                              * If applicable, the Sales Tax will appear on your
                              invoice, and is not included in this quote.
                            </Typography>
                          </Box>
                          <Stack>
                            <TextField
                              sx={{ maxWidth: "300px" }}
                              color="primary"
                              margin="normal"
                              id="signee"
                              name="signee"
                              label="Approved By"
                              value={formik.values.signee}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.signee && (
                              <Box>
                                <Typography color={"error"}>
                                  {formik.errors.signee}
                                </Typography>
                              </Box>
                            )}
                            <TextField
                              sx={{ maxWidth: "300px" }}
                              color="primary"
                              margin="normal"
                              id="signeeEmail"
                              name="signeeEmail"
                              label="Email"
                              value={formik.values.signeeEmail}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.signeeEmail && (
                              <Box>
                                <Typography color={"error"}>
                                  {formik.errors.signeeEmail}
                                </Typography>
                              </Box>
                            )}
                          </Stack>
                          {order.estimateVersion == "v1" && (
                            <>
                              <Typography>Sign Here</Typography>
                              <Card
                                sx={{
                                  background: "#d2d2d2",
                                  width: 310,
                                  height: 100,
                                }}
                              >
                                <ReactSignatureCanvas
                                  ref={signatureRef}
                                  backgroundColor="rgba(0, 0, 0, 0)"
                                  penColor="black"
                                  canvasProps={{
                                    width: 310,
                                    height: 100,
                                    className: "sigCanvas",
                                  }}
                                />
                              </Card>
                            </>
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              magin: 0,
                            }}
                          >
                            <Checkbox
                              style={{
                                margin: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              checked={isEstimateTermsOfServiceAgreementChecked}
                              onChange={(e) => {
                                setIsEstimateTermsOfServiceAgreementChecked(
                                  !isEstimateTermsOfServiceAgreementChecked
                                );
                              }}
                            />
                            <Typography
                              color="primary"
                              style={{
                                marginTop: 2,
                                padding: 0,
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                fontSize: "16px",
                              }}
                              onClick={() => {
                                setIsEstimateTermsOfServiceAgreementChecked(
                                  !isEstimateTermsOfServiceAgreementChecked
                                );
                              }}
                            >
                              I agree to Industry's Terms
                            </Typography>
                          </div>

                          {order.estimateVersion == "v2" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                magin: 0,
                              }}
                            >
                              <Checkbox
                                style={{
                                  margin: 0,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                                checked={isEstimateMaterialAndColorConfirmed}
                                onChange={(e) => {
                                  setIsEstimateMaterialAndColorConfirmed(
                                    !isEstimateMaterialAndColorConfirmed
                                  );
                                }}
                              />
                              <Typography
                                color="primary"
                                style={{
                                  marginTop: 2,
                                  padding: 0,
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  setIsEstimateMaterialAndColorConfirmed(
                                    !isEstimateMaterialAndColorConfirmed
                                  );
                                }}
                              >
                                I agree with the material and color
                              </Typography>
                            </div>
                          )}
                          {order.estimateVersion == "v2" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                magin: 0,
                              }}
                            >
                              <Checkbox
                                style={{
                                  margin: 0,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                                checked={isEstimateGaugeConfirmed}
                                onChange={(e) => {
                                  setIsEstimateGaugeConfirmed(
                                    !isEstimateGaugeConfirmed
                                  );
                                }}
                              />
                              <Typography
                                color="primary"
                                style={{
                                  marginTop: 2,
                                  padding: 0,
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  setIsEstimateGaugeConfirmed(
                                    !isEstimateGaugeConfirmed
                                  );
                                }}
                              >
                                I agree with the gauge/thickness
                              </Typography>
                            </div>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </ApproveEstimateDialog>
          </>
        )}
    </Box>
  );
};

export default OrderWithEstimateDetails;
