import {
  Box,
  Button,
  Card,
  Checkbox,
  Collapse,
  Fab,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ezorder from "../../api/ezorderNoAuth";
import Accessory from "../shop/Orders/Order/Accessory";
import BenchWork from "../shop/Orders/Order/BenchWork";
import Cleat from "../shop/Orders/Order/Cleat";
import CopingCap from "../shop/Orders/Order/CopingCap";
import Panel from "../shop/Orders/Order/Panel";
import SplicePlate from "../shop/Orders/Order/SplicePlate";
import TrimAndFlashing from "../shop/Orders/Order/TrimAndFlashing";
import MaterialSummary from "../shop/Orders/Order/MaterialSummary";
import estimatePdfDownloadHandler from "../../helper/estimatePdfDownloader";
import { Check, Download } from "@mui/icons-material";
import ReactSignatureCanvas from "react-signature-canvas";
import getEstimateDocDefinition from "../../pdf-helpers/EstimateHelper";
import TermsOfAgreement from "./TermsOfAgreement";
import { SnackAlertContext } from "../../context/SnackAlertContext";
import { LoadingButton } from "@mui/lab";
import moment from "moment/moment";
import OrderTitle from "../../components/OrderTitle";
import DeclineEstimateModal from "../customer/Orders/Order/Views/DeclineEstimateModal";
import { useTheme } from "@emotion/react";
import CommentSection from "../customer/Orders/Order/Views/CommentSection";
import RenewEstimateModal from "../customer/Orders/Order/Views/RenewEstimateModal";
import {
  toThreeDecimals,
  toTwoDecimals,
  applyMarkup,
} from "../../components/NewDetail/calculations/utils";
import { orderTotals } from "../../components/NewDetail/calculations/orderTotals";
import SetPrimaryContactDialog from "../customer/Orders/Order/Views/SetPrimaryContactDialog";
import FlatSheet from "../shop/Orders/Order/FlatSheet";
import Coil from "../shop/Orders/Order/Coil";
import ApproveEstimateDialog from "../../components/ApproveEstimateDialog";
import { AuthContext } from "../../context/AuthContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Sentry from "@sentry/react";

pdfMake.fonts = {
  Roboto: {
    normal:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-Regular.ttf",
    bold: "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-Medium.ttf",
    italics:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-BoldItalic.ttf",
    bolditalics:
      "https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/Roboto-MediumItalic.ttf",
  },
  AlexBrush: {
    normal: `https://ezorder-public.s3.us-east-2.amazonaws.com/fonts/AlexBrush-Regular.ttf`,
  },
};

const ApproveEstimate = ({ isGuest = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const controller = new AbortController();
  const [collapsedTOS, setCollapsedTOS] = useState(false);
  const { orderId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [order, setOrder] = useState(null);
  const [isSalesTaxApplicable, setIsSalesTaxApplicable] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [readOnly, setReadOnly] = useState(true);
  const [productMap, setProductMap] = useState(new Map());
  const [refreshing, setRefreshing] = useState(false);
  const [comments, setComments] = useState([]);
  const [vendorMarkupPercentage, setVendorMarkupPercentage] = useState(0);

  const [
    isEstimateTermsOfServiceAgreementChecked,
    setIsEstimateTermsOfServiceAgreementChecked,
  ] = useState(false);

  // User Confirm Gauge
  const [isEstimateGaugeConfirmed, setIsEstimateGaugeConfirmed] =
    useState(false);

  // User Confirm Material/Color
  const [
    isEstimateMaterialAndColorConfirmed,
    setIsEstimateMaterialAndColorConfirmed,
  ] = useState(false);

  const [isApproving, setIsApproving] = useState(false);

  const signatureRef = useRef();

  const { openSnackMessage } = useContext(SnackAlertContext);

  const [isAddingComment, setIsAddingComment] = useState(false);

  const [declineEstimateModalOpen, setDeclineEstimateModalOpen] =
    useState(false);
  const [renewEstimateModalOpen, setRenewEstimateModalOpen] = useState(false);
  const [primaryContactDialogOpen, setPrimaryContactDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [isEstimateExpired, setIsEstimateExpired] = useState(false);

  const [approveDialogOpen, setApproveDialogOpen] = useState(false);

  const addComment = async (comment) => {
    try {
      setIsAddingComment(true);

      let requestBody = {
        comment,
        token: searchParams.get("token"),
      };

      const response = await ezorder.post(
        `/no-auth/orders/${order.id}/comment`,
        requestBody
      );
      openSnackMessage("success", "Comment Added");
      getOrderComments();
    } catch (error) {
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsAddingComment(false);
      // setComment("");
    }
  };

  const getOrderComments = async (controller) => {
    try {
      setLoading(true);
      let data = {};
      if (controller) {
        data.signal = controller.signal;
      }
      const res = await ezorder.get(
        `/no-auth/orders/${orderId}/comments?token=${searchParams.get(
          "token"
        )}`,
        data
      );
      setComments(res.data.comments);
    } catch (error) {
      console.log("ERROR", error);
    } finally {
      setLoading(false);
    }
  };

  const declineEstimateWithToken = async (cancelOrder, commentText) => {
    try {
      setLoading(true);
      console.log("Declining Estimate");
      let requestBody = {
        token: searchParams.get("token"),
        cancelOrder, // boolean
        comment: commentText,
      };
      const response = await ezorder.put(
        `/no-auth/orders/${order.id}/estimate/decline`,
        requestBody
      );
      getOrderByIdWithAccessToken(orderId, searchParams.get("token"));
      openSnackMessage(
        "success",
        "Changes requested",
        "We will send your new quote through email"
      );
      if (!isGuest) {
        navigate("/quotes");
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const requestNewEstimateForExpired = async (commentText) => {
    try {
      setLoading(true);
      console.log("Requesting a new quote for expired");

      let requestBody = {
        token: searchParams.get("token"),
        comment: commentText,
      };

      const response = await ezorder.put(
        `/no-auth/orders/${orderId}/estimate/request-new`,
        requestBody
      );
      getOrderByIdWithAccessToken(orderId, searchParams.get("token"));
      openSnackMessage(
        "success",
        "New Quote requested",
        "We will send your new quote through email"
      );
      if (!isGuest) {
        navigate("/quotes");
      }
    } catch (error) {
      Sentry.captureException(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const getOrderByIdWithAccessToken = async (id, accessToken, controller) => {
    try {
      console.log("GET ORDER BY ID");
      setRefreshing(true);
      let data = {
        // headers: {
        //   Authorization: `Bearer ${authToken}`,
        // },
      };

      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.get(
        `/no-auth/orders/${id}?token=${accessToken}`,
        data
      );
      //console.log(response.data.order);
      let orderDetails = response.data.order;
      setOrder(response.data.order);

      setVendorMarkupPercentage(response.data.order.vendorMarkupPercentage);

      let expired =
        orderDetails.orderStatus &&
        orderDetails.estimateExpirationDate &&
        orderDetails.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" &&
        moment(orderDetails.estimateExpirationDate) < moment();

      setIsEstimateExpired(expired);

      const prodMap = new Map();
      for (let i = 0; i < orderDetails.items.length; i++) {
        let orderItem = orderDetails.items[i];
        prodMap.set(`${orderItem.objectType}`, orderItem);
      }
      setProductMap(prodMap);
      console.log("PRODUCT MAP", prodMap);
    } catch (error) {
      console.log("ERRORRRRR");
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const getOrderSigneeDetailsWithAccessToken = async (id, controller) => {
    try {
      setRefreshing(true);
      let data = {
        // headers: {
        //   Authorization: `Bearer ${authToken}`,
        // },
      };

      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.get(
        `/no-auth/orders/${id}/tokens/${searchParams.get("token")}`,
        data
      );

      setAccessToken(response.data.accessToken);
      setReadOnly(response.data.accessToken.readOnly);
    } catch (error) {
      console.log("ERRORRRRR");
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const notifyAdminLinkWasUsed = async (id, token, controller) => {
    try {
      setRefreshing(true);
      let data = {
        // headers: {
        //   Authorization: `Bearer ${authToken}`,
        // },
      };

      if (controller) {
        data.signal = controller.signal;
      }
      const response = await ezorder.get(
        `/no-auth/orders/${id}/tokens/${token}/used`,
        data
      );

      setReadOnly(response.data.accessToken.readOnly);
    } catch (error) {
      console.log("ERRORRRRR");
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const handleApprove = async (base64Signature, orderContact) => {
    console.log("Handle Approve", base64Signature, orderContact);
    generateSignedEstimateForOrder(
      base64Signature,
      formik.values.signee,
      formik.values.signeeEmail,
      isSalesTaxApplicable,
      orderContact
    );
  };

  let getEstimateBlobPromise = function (pdfGenerator) {
    return new Promise((resolve, reject) => {
      pdfGenerator.getBlob((blob) => {
        console.log("BLOB", blob);
        resolve(blob);
      });
    });
  };

  const generateSignedEstimateForOrder = async (
    signatureBase64,
    signeeFullName,
    signeeEmail,
    isSalesTaxApplicable,
    orderContact
  ) => {
    try {
      setIsApproving(true);
      if (Object.keys(formik.errors).length)
        throw "Please enter your full name and email";
      if (!isEstimateTermsOfServiceAgreementChecked)
        throw "You must agree to Industry's Service Agreement terms before approving this quote";
      if (order.estimateVersion == "v2" && !isEstimateMaterialAndColorConfirmed)
        throw "You must confirm the selected material and color are correct";
      if (order.estimateVersion == "v2" && !isEstimateGaugeConfirmed)
        throw "You must confirm that the gauge/thickness is correct";
      // Then Generate Estimate
      const accessToken = searchParams.get("token");
      const docDef = await getEstimateDocDefinition(
        orderId,
        null,
        true,
        accessToken,
        signatureBase64,
        signeeFullName,
        true,
        isSalesTaxApplicable,
        null,
        null,
        signeeEmail
      );

      // let fonts = {
      //   Roboto: {
      //     normal: "fonts/Roboto-Regular.ttf",
      //     bold: "fonts/Roboto-Medium.ttf",
      //     italics: "fonts/Roboto-Italic.ttf",
      //     bolditalics: "fonts/Roboto-MediumItalic.ttf",
      //   },
      //   AlexBrush: {
      //     normal: "fonts/AlexBrush-Regular.ttf",
      //   },
      // };
      // let pdfGenerator = pdfMake.createPdf(docDef, null, fonts); //.open();
      let pdfGenerator = pdfMake.createPdf(docDef); //.open();

      let estimatePdfBlob = await getEstimateBlobPromise(pdfGenerator);
      const presignedUrlResponse = await createWritePresignedUrlWithAccessToken(
        "WRITE",
        "estimate.pdf",
        estimatePdfBlob.contentType,
        "signed-estimates",
        accessToken
      );
      const presignedUploadUrl = presignedUrlResponse.uploadUrl;
      const s3Key = presignedUrlResponse.key;

      // Upload Estimate PDF
      await fetch(presignedUploadUrl, {
        method: "PUT",
        body: estimatePdfBlob,
        headers: {
          "Content-Type": estimatePdfBlob.contentType, // DO NOT PASS IN A BEARER TOKEN
        },
      });

      // Update Order with new Estimate
      // Create endpoint to update status and set signed estimate s3 key
      const response = await ezorder.put(
        `/no-auth/orders/${order.id}/sign-estimate`,
        {
          token: searchParams.get("token"),
          signedEstimatePDF: s3Key,
          estimateApprovalDate: new Date(),
          isEstimateTermsOfServiceAgreementChecked,
          isEstimateMaterialAndColorConfirmed,
          isEstimateGaugeConfirmed,
          orderContact,
          estimateApprovedByFullName: signeeFullName,
          estimateApprovedByEmail: signeeEmail,
        }
      );
      if (response.status == 200 || response.status === 201) {
        openSnackMessage(
          "success",
          "Quote Approved",
          "Thank you! Once your order is processed, you will receive the invoice and order progress notifications via email"
        );
        getOrderByIdWithAccessToken(
          orderId,
          searchParams.get("token"),
          controller
        );
        if (!isGuest) {
          navigate("/quotes");
        } else {
          setPrimaryContactDialogOpen(false);
        }
      } else {
        openSnackMessage("error", "Approval Failed");
        Sentry.captureException(new Error("Failed to approve estimate"));
      }
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage(
          "error",
          error.response.data.error,
          "Something went wrong (e1)"
        );
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error, "Something went wrong (e2)");
      }
    } finally {
      setIsApproving(false);
    }
  };

  const generateMarkedUpEstimateForOrder = async () => {
    try {
      setIsApproving(true);
      // Then Generate Estimate
      const accessToken = searchParams.get("token");
      const docDef = await getEstimateDocDefinition(
        orderId,
        null,
        true, //isNoAuth
        accessToken,
        null,
        null,
        true,
        null,
        vendorMarkupPercentage,
        true, // includeCompanyLogo,
        null //signeeEmail
      );

      // pdfMake.createPdf(docDef).open();

      let pdfGenerator = pdfMake.createPdf(docDef); //.open();
      // pdfGenerator.open();
      let pdfName = `VENDOR-QUOTE-${order.id}-${order.company.name}-${order.project.name}`;
      pdfGenerator.download(pdfName);
    } catch (error) {
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage(
          "error",
          error.response.data.error,
          "Something went wrong (e3)"
        );
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error, "Something went wrong (e4)");
      }
    } finally {
      setIsApproving(false);
    }
  };

  const saveVendorMarkup = async () => {
    try {
      setIsApproving(true);

      const accessToken = searchParams.get("token");

      // Update Order with new Vendor Markup
      // Create endpoint to update status and set signed estimate s3 key
      const response = await ezorder.put(
        `/no-auth/orders/${order.id}/vendor-markup`,
        {
          token: searchParams.get("token"),
          vendorMarkupPercentage,
        }
      );
      openSnackMessage("success", "Markup Saved");
      getOrderByIdWithAccessToken(
        orderId,
        searchParams.get("token"),
        controller
      );
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    } finally {
      setIsApproving(false);
    }
  };

  const createWritePresignedUrlWithAccessToken = async (
    actionType,
    fileName,
    contentType,
    resource,
    token
  ) => {
    console.log("Create Presigned URL");

    const identifier =
      order.company && order.company.id ? order.company.id : "GUEST";

    const response = await ezorder.post("/no-auth/files/presignedUrl", {
      actionType,
      fileName,
      contentType,
      resource,
      identifier,
      token,
    });

    return response.data;
  };

  const signeeFormSchema = Yup.object().shape({
    signee: Yup.string().required("Full Name is required"),
    signeeEmail: Yup.string().email().required("Email is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      signee: "",
      signeeEmail: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: signeeFormSchema,
    onSubmit: (values) => {
      // handleSubmit(values);
    },
  });

  useEffect(() => {
    const controller = new AbortController();
    if (orderId) {
      getOrderComments(controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  useEffect(() => {
    // const controller = new AbortController();
    if (orderId) {
      getOrderByIdWithAccessToken(
        orderId,
        searchParams.get("token"),
        controller
      );
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  useEffect(() => {
    // const controller = new AbortController();
    if (orderId) {
      getOrderSigneeDetailsWithAccessToken(orderId, controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  useEffect(() => {
    if (accessToken) {
      console.log(accessToken);
      // formik.setFieldValue("signee", accessToken.customer, true);
      // formik.setFieldValue("signeeEmail", accessToken.email, true);
      formik.setValues(
        { signee: accessToken.customer, signeeEmail: accessToken.email },
        true
      );
    }
  }, [accessToken]);

  useEffect(() => {
    // const controller = new AbortController();
    if (orderId) {
      notifyAdminLinkWasUsed(orderId, searchParams.get("token"), controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [orderId]);

  if (!order) return null;

  let totals = null;
  if (order.estimatePdf) {
    totals = orderTotals(order.items, order.skidCharge);
  }
  return (
    <Box sx={{ marginBottom: "140px" }}>
      <OrderTitle order={order} />
      {/* <Typography variant="h3" sx={{ textAlign: "center" }}>
        Order #{orderId}
      </Typography>
      {order.estimatePdf && totals && (
        <Typography variant="h5" sx={{ textAlign: "left" }}>
          Total: ${Math.round(parseFloat(totals.outTheDoorCost))}
        </Typography>
      )} */}
      <Box>
        {order.estimatePdf && (
          <Button
            onClick={() =>
              estimatePdfDownloadHandler(
                order.estimatePdf,
                null,
                true,
                searchParams.get("token"),
                `CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
              )
            }
          >
            <Download color="primary" />
            Quote PDF
          </Button>
        )}
        {order.isEstimateApproved && (
          <Button
            onClick={() =>
              estimatePdfDownloadHandler(
                order.signedEstimatePDF,
                null,
                true,
                searchParams.get("token"),
                `SIGNED-CUSTOMER-QUOTE-${order.id}-${order.company.name}-${order.project.name}.pdf`
              )
            }
          >
            <Download color="primary" />
            Signed Quote PDF
          </Button>
        )}
      </Box>

      <Box sx={{ marginTop: "32px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {order.company.isVendorOrSupplier
                        ? "Customer"
                        : "Project"}
                    </TableCell>
                    {order.company.isVendorOrSupplier && (
                      <TableCell>Project</TableCell>
                    )}
                    <TableCell>Sub-Project</TableCell>
                    <TableCell>PO #</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {order.project.name}
                    </TableCell>
                    {order.company.isVendorOrSupplier && (
                      <TableCell component="th" scope="row">
                        {order.vendorProjectName}
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {order.subProject}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {order.poNumber}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box> */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            {order.isCustomMaterial ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Custom Material</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{order.customColor}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Color/Material</TableCell>
                      <TableCell>Gauge</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {order.color} - {order.manufacturer}
                      </TableCell>
                      <TableCell>{order.gauge}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
          {/* <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <TextField
              label="Order Notes"
              multiline
              maxRows={3}
              value={order.notes}
            />
          </Box> */}
        </Box>
      </Box>

      <Box sx={{ marginTop: "32px" }}>
        <Typography variant="h5">Components Included</Typography>
        <Box sx={{ padding: "8px" }}>
          {order?.items?.find((i) => i.objectType === "FlatSheet") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Flat Sheet</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "Coil") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Coil</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "Panel") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Panel</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "TrimAndFlashing") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Tim & Flashing</Typography>
            </Box>
          )}

          {order?.items?.find((i) => i.objectType === "BenchWork") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>BenchWork</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "CopingCap") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Coping Cap</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "CopingCapCleat") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Coping Cap Cleat</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "SplicePlate") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Splice Plate</Typography>
            </Box>
          )}
          {order?.items?.find((i) => i.objectType === "Accessory") && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Check sx={{ marginRight: "8px" }} />
              <Typography>Extra Accessories</Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Typography variant="h5" sx={{ marginTop: "32px" }}>
        Order Summary
      </Typography>

      {productMap.get("FlatSheet") && (
        <FlatSheet
          flatSheet={productMap.get("FlatSheet")}
          sx={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      {productMap.get("Coil") && (
        <Coil
          coil={productMap.get("Coil")}
          sx={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      {productMap.get("Panel") && (
        <Panel
          panel={productMap.get("Panel")}
          sx={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}
      {productMap.get("TrimAndFlashing") && (
        <TrimAndFlashing
          accessToken={searchParams.get("token")}
          trim={productMap.get("TrimAndFlashing")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("BenchWork") && (
        <BenchWork
          accessToken={searchParams.get("token")}
          benchWork={productMap.get("BenchWork")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("CopingCap") && (
        <CopingCap
          accessToken={searchParams.get("token")}
          copingCap={productMap.get("CopingCap")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("CopingCapCleat") && (
        <Cleat
          accessToken={searchParams.get("token")}
          cleat={productMap.get("CopingCapCleat")}
          style={{ marginTop: "32px" }}
          includeImages={false}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
          isMinifiedList={true}
        />
      )}
      {productMap.get("SplicePlate") && (
        <SplicePlate
          splicePlate={productMap.get("SplicePlate")}
          style={{ marginTop: "32px" }}
          isEstimate={true}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}
      {productMap.get("Accessory") && (
        <Accessory
          accessory={productMap.get("Accessory")}
          style={{ marginTop: "32px", marginBottom: "32px" }}
          showTotal={true}
          showPricePerPiece={order.isItemizedEstimate}
        />
      )}

      <Typography variant="h5" sx={{ marginTop: "32px" }}>
        Skid Charge (Pallet Charge) - $
        {Math.round(parseFloat(order.skidCharge ? order.skidCharge : 0.0))}
      </Typography>

      {order.estimatePdf && (
        <Typography variant="h5" sx={{ textAlign: "left", marginTop: "32px" }}>
          Total: ${Math.round(parseFloat(totals.outTheDoorCost))}
        </Typography>
      )}
      {order.company.isVendorOrSupplier && (
        <Box
          sx={{
            marginTop: "32px",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h5" color="primary">
            Vendor Markup
          </Typography>
          <Card
            sx={{
              marginTop: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "16px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: "16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                type="number"
                color="primary"
                margin="normal"
                label="Markup %"
                size="small"
                value={vendorMarkupPercentage}
                onFocus={(e) => e.target.select()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  if (e.target.value)
                    setVendorMarkupPercentage(toTwoDecimals(e.target.value));
                  else {
                    setVendorMarkupPercentage(toTwoDecimals(0));
                  }
                }}
              />
              <LoadingButton
                disabled={
                  vendorMarkupPercentage == order.vendorMarkupPercentage
                }
                sx={{ marginLeft: "8px" }}
                loading={isApproving}
                color="success"
                variant="contained"
                onClick={() => {
                  saveVendorMarkup();
                }}
              >
                Save Markup
              </LoadingButton>
            </Box>

            <TableContainer component={Paper}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Original</TableCell>
                    <TableCell align="right">Mark Up</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      $
                      {Math.round(
                        toThreeDecimals(
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost
                        )
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ color: "lightgreen" }}>
                      + $
                      {Math.round(
                        applyMarkup(
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost,
                          vendorMarkupPercentage
                        ) -
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost
                      )}
                    </TableCell>
                    <TableCell align="right">
                      $
                      {Math.round(
                        applyMarkup(
                          orderTotals(order.items, order.skidCharge)
                            .outTheDoorCost,
                          vendorMarkupPercentage
                        )
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography>
              * To include your company logo on the PDF, upload your logo on
              your company settings
            </Typography>
            {/* {order.orderStatus.name != "SAVED" &&
              order.orderStatus.name != "QUOTE_REQUESTED" && ( */}
            <LoadingButton
              loading={isApproving}
              onClick={() => {
                generateMarkedUpEstimateForOrder();
              }}
            >
              <Download color="primary" />
              Vendor Markup Quote
            </LoadingButton>
            {/* )} */}
          </Card>
        </Box>
      )}

      {accessToken && (
        <SetPrimaryContactDialog
          defaultName={accessToken.customer}
          defaultPhone={accessToken.phone}
          defaultEmail={accessToken.email}
          open={primaryContactDialogOpen}
          onSubmit={(orderContact) => {
            console.log("SUBMITTED", orderContact);
            if (order.estimateVersion == "v1") {
              console.log("EstimateVersion == v1");
              let base64Image = signatureRef.current
                .getTrimmedCanvas()
                .toDataURL("image/png");
              console.log("Signature base64Image", base64Image);
              handleApprove(base64Image, orderContact);
            } else {
              console.log("EstimateVersion != v1");
              handleApprove(null, orderContact);
            }
          }}
          handleClose={() => setPrimaryContactDialogOpen(false)}
          isLoading={loading || isApproving}
        />
      )}

      <DeclineEstimateModal
        open={declineEstimateModalOpen}
        onDeclineEstimate={declineEstimateWithToken}
        handleClose={() => setDeclineEstimateModalOpen(false)}
        loading={loading}
      />
      <RenewEstimateModal
        open={renewEstimateModalOpen}
        onRenewEstimate={requestNewEstimateForExpired}
        handleClose={() => setRenewEstimateModalOpen(false)}
        loading={loading}
      />
      <CommentSection
        comments={comments}
        addComment={addComment}
        loading={isAddingComment}
      />

      {!readOnly &&
        order.orderStatus &&
        order.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED" && (
          <>
            <Fab
              sx={{
                position: "fixed",
                bottom: 70,
                right: isSmallScreen ? 16 : 56,
              }}
              color="success"
              variant="extended"
              onClick={() => {
                setApproveDialogOpen(true);
              }}
            >
              Review and Approve
            </Fab>
            <ApproveEstimateDialog
              open={approveDialogOpen}
              setOpen={setApproveDialogOpen}
              // titleContent={(isExpanded) => {
              //   return (
              //     <Box
              //       style={{
              //         width: "100%",
              //         display: "flex",
              //         flexDirection: "row",
              //         alignItems: "center",
              //       }}
              //     >
              //       <Typography
              //         variant="h6"
              //         color="primary"
              //         style={{ flex: 1, fontWeight: "bold" }}
              //       >
              //         Review And Approve
              //       </Typography>
              //       {!isExpanded && (
              //         <Button
              //           sx={{ marginRight: "8px" }}
              //           loading={isApproving}
              //           color="success"
              //           variant="contained"
              //           onClick={null}
              //         >
              //           Review
              //         </Button>
              //       )}
              //     </Box>
              //   );
              // }}
              actionAreaContent={() => {
                return (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {order.estimateVersion == "v1" && (
                        <Button
                          onClick={() => {
                            signatureRef.current.clear();
                          }}
                        >
                          Clear
                        </Button>
                      )}

                      {/* <Button
                  sx={{ marginLeft: "8px" }}
                  variant="contained"
                  onClick={() => {
                    console.log(order);
                    setDeclineEstimateModalOpen(true);
                  }}
                >
                  Request Changes
                </Button> */}
                      <LoadingButton
                        sx={{ marginLeft: "8px" }}
                        loading={isApproving}
                        color="success"
                        variant="contained"
                        onClick={() => {
                          try {
                            if (Object.keys(formik.errors).length)
                              throw "Please enter your full name and email";

                            if (!isEstimateTermsOfServiceAgreementChecked)
                              throw "You must agree to Industry's Service Agreement terms before approving this quote";
                            if (
                              order.estimateVersion == "v2" &&
                              !isEstimateMaterialAndColorConfirmed
                            )
                              throw "You must confirm the selected material and color are correct";
                            if (
                              order.estimateVersion == "v2" &&
                              !isEstimateGaugeConfirmed
                            )
                              throw "You must confirm that the gauge/thickness is correct";

                            if (
                              order.estimateVersion == "v1" &&
                              signatureRef.current.isEmpty()
                            ) {
                              throw "Signature required";
                            }
                            setPrimaryContactDialogOpen(true);
                          } catch (error) {
                            openSnackMessage("error", error);
                          }
                        }}
                      >
                        Approve
                      </LoadingButton>
                    </Box>
                  </Box>
                );
              }}
            >
              <Box>
                <Box
                  sx={{
                    marginTop: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    // padding: "16px",
                  }}
                >
                  {order.isUnconfirmedCutlist ? (
                    <Box>
                      <Typography sx={{ color: "red" }}>
                        THIS QUOTE IS FOR ESTIMATING ONLY AND CANNOT BE APPROVED
                        FOR PRODUCTION. TO ORDER THESE ITEMS, YOU MUST REQUEST A
                        NEW QUOTE FOR PRODUCTION.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          sx={{ marginLeft: "8px" }}
                          variant="contained"
                          onClick={() => {
                            navigate(
                              `/quotes/${order.id}/resubmit-quote-request`
                            );
                          }}
                        >
                          Edit and Request a New Quote
                        </Button>
                        <Button
                          sx={{ marginLeft: "8px" }}
                          variant="contained"
                          onClick={() => {
                            console.log(order);
                            setRenewEstimateModalOpen(true);
                          }}
                        >
                          Request A New Quote
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <TermsOfAgreement
                        estimateVersion={order.estimateVersion}
                      />
                      {isEstimateExpired ? (
                        <Box sx={{ width: "100%", marginTop: "16px" }}>
                          <Typography sx={{ color: "red" }}>
                            This Quote is no longer valid for approval. Our
                            quotes are valid for 30 days.
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              sx={{ marginLeft: "8px" }}
                              variant="contained"
                              onClick={() => {
                                console.log(order);
                                setRenewEstimateModalOpen(true);
                              }}
                            >
                              Request A New Quote
                            </Button>
                          </Box>
                        </Box>
                      ) : (
                        <Box sx={{ width: "100%", marginTop: "16px" }}>
                          <Box sx={{ marginBottom: "8px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                magin: 0,
                              }}
                            >
                              <Checkbox
                                style={{
                                  magin: 0,
                                  padding: 0,
                                }}
                                checked={isSalesTaxApplicable}
                                onChange={(e) => {
                                  setIsSalesTaxApplicable(e.target.checked);
                                }}
                              />
                              <Typography
                                color="primary"
                                sx={{ fontWeight: "bold" }}
                              >
                                Is Sales Tax Applicable to this Order?
                              </Typography>
                            </div>

                            <Typography
                              sx={{ marginLeft: "16px", fontSize: "14px" }}
                            >
                              * If applicable, the Sales Tax will appear on your
                              invoice, and is not included in this quote.
                            </Typography>
                          </Box>
                          <Stack>
                            <TextField
                              sx={{ maxWidth: "300px" }}
                              color="primary"
                              margin="normal"
                              id="signee"
                              name="signee"
                              label="Approved By"
                              value={formik.values.signee}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.signee && (
                              <Box>
                                <Typography color={"error"}>
                                  {formik.errors.signee}
                                </Typography>
                              </Box>
                            )}
                            <TextField
                              sx={{ maxWidth: "300px" }}
                              color="primary"
                              margin="normal"
                              id="signeeEmail"
                              name="signeeEmail"
                              label="Email"
                              value={formik.values.signeeEmail}
                              onChange={formik.handleChange}
                            />
                            {formik.errors.signeeEmail && (
                              <Box>
                                <Typography color={"error"}>
                                  {formik.errors.signeeEmail}
                                </Typography>
                              </Box>
                            )}
                          </Stack>
                          {order.estimateVersion == "v1" && (
                            <>
                              <Typography>Sign Here</Typography>
                              <Card
                                sx={{
                                  background: "#d2d2d2",
                                  width: 310,
                                  height: 100,
                                }}
                              >
                                <ReactSignatureCanvas
                                  ref={signatureRef}
                                  backgroundColor="rgba(0, 0, 0, 0)"
                                  penColor="black"
                                  canvasProps={{
                                    width: 310,
                                    height: 100,
                                    className: "sigCanvas",
                                  }}
                                />
                              </Card>
                            </>
                          )}

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              magin: 0,
                            }}
                          >
                            <Checkbox
                              style={{
                                margin: 0,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              checked={isEstimateTermsOfServiceAgreementChecked}
                              onChange={(e) => {
                                setIsEstimateTermsOfServiceAgreementChecked(
                                  !isEstimateTermsOfServiceAgreementChecked
                                );
                              }}
                            />
                            <Typography
                              color="primary"
                              style={{
                                marginTop: 2,
                                padding: 0,
                                fontWeight: "bold",
                                textTransform: "capitalize",
                                fontSize: "16px",
                              }}
                              onClick={() => {
                                setIsEstimateTermsOfServiceAgreementChecked(
                                  !isEstimateTermsOfServiceAgreementChecked
                                );
                              }}
                            >
                              I agree to Industry's Terms
                            </Typography>
                          </div>

                          {order.estimateVersion == "v2" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                magin: 0,
                              }}
                            >
                              <Checkbox
                                style={{
                                  margin: 0,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                                checked={isEstimateMaterialAndColorConfirmed}
                                onChange={(e) => {
                                  setIsEstimateMaterialAndColorConfirmed(
                                    !isEstimateMaterialAndColorConfirmed
                                  );
                                }}
                              />
                              <Typography
                                color="primary"
                                style={{
                                  marginTop: 2,
                                  padding: 0,
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  setIsEstimateMaterialAndColorConfirmed(
                                    !isEstimateMaterialAndColorConfirmed
                                  );
                                }}
                              >
                                I agree with the material and color
                              </Typography>
                            </div>
                          )}
                          {order.estimateVersion == "v2" && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                magin: 0,
                              }}
                            >
                              <Checkbox
                                style={{
                                  margin: 0,
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                }}
                                checked={isEstimateGaugeConfirmed}
                                onChange={(e) => {
                                  setIsEstimateGaugeConfirmed(
                                    !isEstimateGaugeConfirmed
                                  );
                                }}
                              />
                              <Typography
                                color="primary"
                                style={{
                                  marginTop: 2,
                                  padding: 0,
                                  fontWeight: "bold",
                                  textTransform: "capitalize",
                                  fontSize: "16px",
                                }}
                                onClick={() => {
                                  setIsEstimateGaugeConfirmed(
                                    !isEstimateGaugeConfirmed
                                  );
                                }}
                              >
                                I agree with the gauge/thickness
                              </Typography>
                            </div>
                          )}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </ApproveEstimateDialog>
          </>
        )}
    </Box>
  );
};

export default ApproveEstimate;
