import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Button,
  Card,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  useMediaQuery,
  DialogContent,
  DialogContentText,
  ListItem,
  ListItemText,
  DialogActions,
  MenuItem,
} from "@mui/material";
import TableSearchAndFilter from "../../components/TableSearchAndFilter";
import CircularProgress from "@mui/material/CircularProgress";
import useOrdersAdmin from "../../hooks/useOrdersAdmin";
import ListFooter from "../../components/InfiniteScroller/ListFooter";
import OrderTableRow from "../../components/OrderTableRow";
import { useInView } from "react-intersection-observer";
import { useDebounce } from "use-debounce";
import InfiniteScrollerTable from "../../components/InfiniteScroller/InfiniteScrollerTable";
import { useTheme } from "@emotion/react";
import SearchBar from "../../components/SearchBar";

const Orders = ({ companyId = null, resource = "orders", activeTab }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { authToken } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    orders,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useOrdersAdmin(authToken, 50, companyId, activeTab);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5, //Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
  });

  const [inViewDebounce] = useDebounce(inView, 1000);

  useEffect(() => {
    if (orders.length > 0 && inView) {
      console.log("Footer Element In View. Load More...");
      onLoadMore();
    }
  }, [inViewDebounce]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Typography variant="h4" component="div">
        Orders
      </Typography>
      <SearchBar
        label="Search Orders"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Tabs
        sx={{ marginBottom: "16px" }}
        value={activeTab}
        onChange={(event, newValue) => {
          navigate(`/${resource}/${newValue}`, { replace: true });
        }}
        centered
      >
        <Tab label="Active" value="active" />
        <Tab label="In-Progress" value="in-progress" />
        <Tab label="Ready For Pickup" value="ready" />
        <Tab label="Fulfilled" value="fulfilled" />
        <Tab label="Unpaid" value="unpaid" />
      </Tabs>
      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>Sales Rep</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>PO #</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell align="right">Project</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Date Created</TableCell>
              <TableCell align="right">Invoice Balance</TableCell>
              <TableCell align="right">Invoice Due Date</TableCell>
              <TableCell align="right">Out The Door Cost</TableCell>
              <TableCell align="right">PDFs</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={orders}
        renderRow={(item, index) => {
          return (
            <OrderTableRow
              key={item.id}
              order={item}
              onSelect={(order) => {
                navigate(`/orders/${order.id}`);
              }}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </Box>
  );
};
export default Orders;
